<template lang="pug">
  .ModalContainer
    #Overlay.Overlay.bg-primary.opacity-50.inset-0.fixed.transition(@click="() => handleCloseModal(true)")
    #Modal.Modal.ModalStyles.bg-white.p-3.w-full.fixed.bottom-0.transition.duration-500.max-w-lg
      header(v-if="!hideHeader").w-full.p-1.flex.justify-end
        button(@click="() => handleCloseModal()").border-none
          .material-icons.text-info_color close
      .contentModal
        div(
          :class="statusClass"
          v-if="responseState"
        ).rounded-bd-complete.w-16.h-16.flex.items-center.justify-center.mx-auto.mb-3.mt-0
          .material-icons.text-3xl(v-if="responseState === 'danger'") error_outline
          .material-icons.text-3xl(v-else-if="responseState === 'warning'") warning_amber
          .material-icons.text-3xl(v-else-if="responseState === 'success'") check_circle
          .text-3xl(v-else-if="responseState === 'loading'")
            loading
          div(v-else)
        slot
</template>

<script>
export default {
  name: "Modal",
  components: {
    Loading: () => import("./general/Loading.vue"),
  },
  props: {
    responseState: String,
    closeModal: Function,
    hideHeader: Boolean,
    cancelCloseOverlay: Boolean,
  },
  created() {
    this.$emit("close-button", this.handleCloseModal);
  },
  computed: {
    statusClass() {
      return (
        this.responseState === "warning" ? " bg-warning_background text-warning_color " :
        this.responseState === "success" ? " bg-good_background text-good_color " :
        this.responseState === "danger" ? " bg-danger_background text-danger_color " : ""
      );
    },
  },
  methods: {
    handleCloseModal(overlay) {
      const $Modal = this.$el.querySelector("#Modal");
      $Modal.classList.remove("Modal");

      const $Overlay = this.$el.querySelector("#Overlay");
      $Overlay.classList.remove("Overlay");

      if (!overlay) {
        this.closeFunction($Modal, $Overlay);
      } else {
        if (!this.cancelCloseOverlay) {
          this.closeFunction($Modal, $Overlay);
        }
      }
    },
    closeFunction($Modal, $Overlay) {
      const timer1 = setTimeout(() => {
        $Modal.classList.add("ModalStatic");
        $Overlay.classList.add("OverlayStatic");
        clearTimeout(timer1);
      }, 200);

      const timer = setTimeout(() => {
        this.closeModal();
        clearTimeout(timer);
      }, 600);
    },
  },
}
</script>

<style scoped>
.Overlay {
  opacity: 0;
  transition: 1s all;
  animation: forwards 0.5s ease-out onlyFade;
  z-index: 9999999999;
}

.OverlayStatic {
  opacity: 1;
  transition: 1s all;
  animation-delay: 2s;
  animation: forwards 0.5s ease-out onlyFade alternate-reverse;
}

.Modal {
  transform: translateY(10000px);
  animation: forwards 0.6s ease-out fadeTop alternate;
  z-index: 999999999999;
}

.ModalStatic {
  animation: forwards 0.6s ease-out fadeTop alternate-reverse;
}

.ModalStyles {
  border-radius: 25px 25px 0 0;
}
</style>